import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { HeaderService } from './header.service';
import { AppService } from '../../app.service'
import { OAuthService } from 'angular-oauth2-oidc'
import { EntitlementService } from '../../shared/service/entitlement.service'
import { USER_PROFILE_FILTER_ARGS, ISO_EDUCATION_FILTER_ARGS, PRIMARY_HOST_DOMAIN } from '../../shared/constants/app.constants'
import { MenuResponse } from 'src/models/menu.model';
declare function loadCustomerSelectionJS() : any;
declare function openCustomerSelectionModal() : any;

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.css']
})

export class HeaderComponent implements OnInit{
  objectKeys = Object.keys;

  menuItems: MenuResponse; // megamenu data set

  userProfileFilterArgs = USER_PROFILE_FILTER_ARGS
  isoEducationFilterArgs = ISO_EDUCATION_FILTER_ARGS

  errorMessage: string = '';
  hasAllProducts = '';
  expandAllProductsDisabled = '';
  dropdownClass = '';
  homePageApiUrl: string = '';
  redirectUrl: string = '';
  customerSupportUrl: string = ''
  hover_megamenu: string = 'allprod' 
  surveyUrl: string = '';
  customerName : string = "";
  isSwitchProfileEnabled : boolean = false;

  constructor(private headerService: HeaderService, 
      private cookieService: CookieService, 
      private appService: AppService,
      private oauthService: OAuthService,
      private entitlementService: EntitlementService) {

    this.appService.getConfigStatus().subscribe(
      _configStatus => {
        if(_configStatus){
          let _appConfig = this.appService.getGlobalConfig();
          this.homePageApiUrl = _appConfig.homePageApiUrl;
          this.redirectUrl = _appConfig.redirectUrl;
          this.customerSupportUrl = _appConfig.veriskCustomerSupport;
          this.surveyUrl = _appConfig.surveyUrl;
          if(_appConfig.switchProfileEnabled == "true"){
            this.isSwitchProfileEnabled = true;
            loadCustomerSelectionJS();
           this.customerName = this.cookieService.get("customername");
           
          }
        }
      },
      error => this.errorMessage = <any>error
    );

  }
  
  ngOnInit(): void {

    this.headerService.getMenuItems().subscribe((data: MenuResponse) => {
      this.menuItems = data;
      if (data.userProducts && data.userProducts.length > 0) {
        this.hover_megamenu = 'myprod';
      }
    });
  }
/*
  signin(): void {
    this.oauthService.initImplicitFlow();
  }
*/
  signout(): void {
    //this.mstrSignout = this.sanitizer.bypassSecurityTrustResourceUrl('http://172.27.176.165/Solutions/asp/TaskAdmin.aspx?taskId=logout&taskEnv=juil_iframe&taskContentType=json');
    this.cookieService.deleteAll('/', PRIMARY_HOST_DOMAIN);
    //this.isAuthenticated = false;
    this.entitlementService.setLoginStatus(false);
    this.oauthService.logOut();
  }

  switchProfileClick()  {
    debugger;
    openCustomerSelectionModal();
    //loadCustomerSelectionJS();
  }

  hrefOpen(url){
    window.open(url, "_blank")
  }

  hoverIn(category: string) {
		//console.log('category:' + category);
    this.hover_megamenu = category;
  }

}
