import { Component, OnInit } from '@angular/core';
import { AuthConfig } from 'angular-oauth2-oidc';
import { CookieService } from 'ngx-cookie-service';
import { AppService } from 'src/app/app.service';
import { EntitlementService } from 'src/app/shared/service/entitlement.service';
import { Router } from '@angular/router';

declare var tableau: any;

@Component({
  selector: 'app-viz-container',
  templateUrl: './viz-container.component.html',
  styleUrls: ['./viz-container.component.css']
})
export class VizContainerComponent implements OnInit {

  authConfig: AuthConfig = {};
  errorMessage: string = '';
  isAuthenticated: boolean = false;
  isEligible: boolean = true;
  viz: any;
  customerSuportUrl: string;
  surveyUrl: string;

  constructor(private appService: AppService, private router: Router,
    private cookieService: CookieService, private entitlementService: EntitlementService) {
        this.appService.getConfigStatus().subscribe(
            _configStatus => {
              if(_configStatus){
                let _appConfig = this.appService.getGlobalConfig();
                this.authConfig.issuer = _appConfig.identityUrl;
                this.authConfig.redirectUri = _appConfig.redirectUrl;
                this.authConfig.clientId = _appConfig.clientId;
                this.authConfig.scope = 'openid';
                this.authConfig.logoutUrl = _appConfig.identityUrl + '/connect/endsession';
                this.customerSuportUrl = _appConfig.veriskCustomerSupport;
                this.surveyUrl = _appConfig.surveyUrl;
                this.getTableauToken(_appConfig.marketwatchApiUrl, _appConfig.tableauHostUrl, _appConfig.tableauSite, _appConfig.tableauDashboard);
              }
            },
            error => this.errorMessage = <any>error
        );
  }


  ngOnInit() {
    
  }

    getTableauToken(apiUrl: string, tableauHost: string, tableauSite:string, dashboard: string): void {
      this.entitlementService.getLoginStatus().subscribe(
        status => {
          this.isAuthenticated = status;
           let isoTicket = this.cookieService.get('ticket');
            this.entitlementService.getTableauToken(apiUrl, isoTicket).subscribe(
              _tableauToken => {
                console.log("Tableau token generated successsfully..." + _tableauToken);
                if(_tableauToken && _tableauToken != "-1"){
                  this.isEligible = true;
                  let url = '';
                  this.entitlementService.getCompanyToken(apiUrl, isoTicket).subscribe(
                    _companyToken =>{
                      url = `${tableauHost}/trusted/${_tableauToken}/t/${tableauSite}/views/${dashboard}?AuthToken=${encodeURIComponent(_companyToken)}`;
                      this.loadTableauDashboard(url);
                    }
                  )
                } else {
                  this.router.navigate(['/unauthorized']);
                }
              }
            )
        }
      )
    }

    loadTableauDashboard(url: string): void{
      let placeholderDiv = document.getElementById('vizContainer');
      let options = {
        hideTabs: true,
        width: '100%',
        height: '800px',
        onFirstInteractive: function(){
          console.log('Tableau Viz finished laoding');
          let iframe = document.getElementById('vizContainer').firstChild as HTMLElement;
          iframe.setAttribute('name', 'stamp ' + Date.now());
        }
      };
      this.viz = new tableau.Viz(placeholderDiv, url, options);
    }
  }

