import { Injectable } from '@angular/core'
import { Observable, BehaviorSubject, throwError } from 'rxjs'
import { catchError, tap } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { IEligibleProduct, PrdEligible } from '../../../models/eligibleproduct.model';

@Injectable({
    providedIn: 'root', //root level, angular creates a single shared instance of this service
})

export class EntitlementService{

    private isAuthenticated: BehaviorSubject<boolean>

    constructor(private http: HttpClient){
        this.isAuthenticated = new BehaviorSubject(false)
    }

    getLoginStatus(): Observable<boolean> {
        return this.isAuthenticated.asObservable()
    }

    setLoginStatus(status: boolean): void{
        this.isAuthenticated.next(status)
    }

    getUserEligibleISOProducts(token: string, isoUserSvcUrl: string): Observable<IEligibleProduct> {
        return this.http.get<IEligibleProduct>(`${isoUserSvcUrl}/getLinksForProductsMyCompanyIsEligibleFor/${token}`).pipe(
          //tap(data => console.log('UserProducts API Data: ' + JSON.stringify(data))),
          catchError(this.handleError)
        );
    }

    getUserRegisteredISOProducts(token: string, isoUserSvcUrl: string): Observable<IEligibleProduct> {
        return this.http.get<IEligibleProduct>(`${isoUserSvcUrl}/getLinksToProductsIAmRegisteredFor/${token}`).pipe(
          //tap(data => console.log('UserProducts API Data: ' + JSON.stringify(data))),
          catchError(this.handleError)
        );
    }

    getTableauToken(apiUrl: string, ticket: string): Observable<string> {
        return this.http.get(`${apiUrl}/tableau/authenticate?ticket=${ticket}&time=${new Date().getTime()}`, {responseType: 'text'}).pipe(
          //tap(data => console.log('UserProducts API Data: ' + JSON.stringify(data))),
          catchError(this.handleError)
        );
    }

    getCompanyToken(apiUrl: string, ticket: string): Observable<string> {
        return this.http.get(`${apiUrl}/tableau/encrypt?ticket=${ticket}&time=${new Date().getTime()}`, {responseType: 'text'}).pipe(
          //tap(data => console.log('UserProducts API Data: ' + JSON.stringify(data))),
          catchError(this.handleError)
        );
    }

    getProductEligibility(apiUrl: string, ticket: string) : Observable<PrdEligible> {
        return this.http.get<PrdEligible>(`${apiUrl}/api/product/eligible?ticket=${ticket}`);
    }

    private handleError(err: HttpErrorResponse) {
        let errorMessage = '';
        if (err.error instanceof ErrorEvent) {
            errorMessage = `An error occurred: ${err.error.message}`;
        } else {
            errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
        }
        console.error(errorMessage);
        return throwError(errorMessage);
    }

}