import { Component } from '@angular/core';
import { AppService } from '../../app.service';

@Component({
    selector: 'unauthorize',
    templateUrl: './unauthorize.component.html',
    styleUrls: ['./unauthorize.css']
  })
  
  export class UnauthorizeComponent {
    customerSuportUrl: string;

    constructor(private appService: AppService) {
        this.appService.getConfigStatus().subscribe(
            _configStatus => {
              if(_configStatus) {
                let _appConfig = this.appService.getGlobalConfig();
                this.customerSuportUrl = _appConfig.veriskCustomerSupport;
              }
        });
    }
  }